import React, { useEffect, useRef } from "react"
import ClientCard from "./clientCard"

const ClientContainer = ({ clients, slider }) => {
  let items = useRef(null)

  useEffect(() => {
    const slider = items.current
    //console.log(document)

    let isDown = false
    let startX
    let scrollLeft

    slider.addEventListener("mousedown", e => {
      isDown = true
      slider.classList.add("active")
      startX = e.pageX - slider.offsetLeft
      scrollLeft = slider.scrollLeft
    })
    slider.addEventListener("mouseleave", () => {
      isDown = false
      slider.classList.remove("active")
    })
    slider.addEventListener("mouseup", () => {
      isDown = false
      slider.classList.remove("active")
    })
    slider.addEventListener("mousemove", e => {
      if (!isDown) return
      e.preventDefault()
      const x = e.pageX - slider.offsetLeft
      const walk = (x - startX) * 3 //scroll-fast
      slider.scrollLeft = scrollLeft - walk
      console.log(walk)
    })
    return () => {}
  }, [])

  return (
    <div
      className="flex overflow-x-scroll overflow-y-hidden no-scrollbar border-black border-t-2 border-b-2"
      style={{ userSelect: "none", cursor: "pointer" }}
      ref={items}
    >
      {clients.map((item, i) => {
        return <ClientCard key={i} client={item} slider={slider} />
      })}
    </div>
  )
}

export default ClientContainer
