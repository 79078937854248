import React from "react"
import { graphql, Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

import Layout from "../components/layout"
import CreateBanner from "../components/createBanner"
import SlidingBanner from "../components/slidingBanner"
import ClientContainer from "../components/clientContainer"
import PageLink from "../components/pageLink"

import Seo from "gatsby-plugin-wpgraphql-seo"
import ImageLoader from "../components/imageLoader"

const IndexPage = ({ data }) => {
  let clients = data.allWpClient.nodes

  console.log(data)

  console.log(clients)

  return (
    <Layout>
      {/* <SEO title="Home" description="amazing home page" /> */}
      <Seo post={data.wpPage} />
      <CreateBanner />
      <div className="mx-auto max-w-screen-2xl">
        <div className="m-8 border-2 border-black">
          <div className="border-b-2 border-black px-10 py-5 text-2xl sm:text-3xl uppercase">
            <h1 className="text-2xl sm:text-3xl">Who We Are</h1>
          </div>
          <div className="grid items-center lg:grid-cols-2 ">
            <div className="pl-8 pt-4 pr-8 flex flex-col h-full content-evenly place-content-around leading-6">
              <div className="whitespace-pre-line">
                {data.wpPage.home.text} <br />
                <br />
                <span className="hidden lg:block">
                  {data.wpPage.home.text2}
                </span>
              </div>

              <div className="hidden lg:flex flex-col sm:flex-row justify-between my-4 2xl:my-10 sm:px-10 sm:text-center">
                <div className="mb-5 sm:mb-0">
                  <PageLink url={data.wpPage.home.link1Url.url}>
                    {data.wpPage.home.link1Text}
                  </PageLink>
                </div>
                <div>
                  <PageLink url={data.wpPage.home.link2Url.url}>
                    {data.wpPage.home.link2Text}
                  </PageLink>
                </div>
              </div>
            </div>
            <div className="w-full h-full border-t-2 border-black lg:border-t-0 lg:border-l-2  ">
              <ImageLoader
                image={data.wpPage.home.image}
                // className="border-black border-r-2"
                objectFit="cover"
                imgStyle={{ objectFit: "cover", zIndex: -999 }}
                className="object-cover h-full w-full -z-50"
              />
            </div>
            <div className="lg:hidden pl-8 pt-4 pr-8 flex flex-col h-full content-evenly place-content-around leading-6 border-black border-t-2">
              <div className="whitespace-pre-line">
                {data.wpPage.home.text2}
              </div>

              <div className="flex lg:hidden flex-col sm:flex-row justify-between my-4 2xl:my-10 sm:px-10 sm:text-center">
                <div className="mb-5 sm:mb-0">
                  <PageLink url={data.wpPage.home.link1Url.url}>
                    {data.wpPage.home.link1Text}
                  </PageLink>
                </div>
                <div>
                  <PageLink url={data.wpPage.home.link2Url.url}>
                    {data.wpPage.home.link2Text}
                  </PageLink>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="px-10 pb-5 pt-20 text-2xl sm:text-3xl uppercase text-right">
          <Link to="/services" className="text-black">
            {" "}
            What we do
          </Link>
        </div>
      </div>
      <Link to="/services" aria-label="Services">
        <SlidingBanner />
        <SlidingBanner reverse />
      </Link>
      <div className="mx-auto ">
        <div className="px-10 py-5 text-2xl sm:text-3xl uppercase text-left">
          <div className="mx-auto max-w-screen-2xl 2xl:px-16 pt-20">
            Client Spotlight
          </div>
        </div>

        <ClientContainer clients={clients} slider />
      </div>
      <div className="mx-auto max-w-screen-2xl my-5 px-3 sm:px-10 2xl:px-28 pt-20">
        We’ve done some cool things with:
      </div>
      <div className="mx-auto max-w-screen-2xl">
        <div className="flex flex-nowrap lg:flex-wrap overflow-x-auto px-5">
          {data.wpPage.logos.logo.map(item => {
            return (
              <div className="flex flex-none lg:flex-auto text-center m-5 w-28 justify-center">
                <ImageLoader
                  image={item}
                  // className="border-black border-r-2"
                  objectFit="contain"
                  imgStyle={{
                    objectFit: "contain",
                    objectPosition: "50% 50%",
                  }}
                />
              </div>
            )
          })}
        </div>
      </div>
      {/* <div>Image 1</div> */}
    </Layout>
  )
}

export default IndexPage

export const pageQuery = graphql`
  query GET_PAGE {
    wpPage(id: { eq: "cG9zdDo3ODk1" }) {
      nodeType
      title
      logos {
        logo {
          altText
          localFile {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
      }
      home {
        link1Text
        link2Text
        image {
          localFile {
            childImageSharp {
              gatsbyImageData
            }
          }
          altText
        }
        link1Url {
          url
        }
        link2Url {
          url
        }
        text
        text2
      }
      uri
      seo {
        title
        metaDesc
        focuskw
        metaKeywords
        metaRobotsNoindex
        metaRobotsNofollow
        opengraphTitle
        opengraphDescription
        opengraphImage {
          altText
          sourceUrl
          srcSet
        }
        twitterTitle
        twitterDescription
        twitterImage {
          altText
          sourceUrl
          srcSet
        }
        canonical
        cornerstone
        schema {
          articleType
          pageType
          raw
        }
      }
    }
    allWpClient(
      filter: { clientDetails: { featured: { eq: true } } }
      sort: { order: ASC, fields: menuOrder }
    ) {
      nodes {
        id
        title
        uri
        clientDetails {
          heroImage {
            altText
            localFile {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
        }
      }
    }
  }
`
