import React, { useEffect, useState } from "react"

import infiniteBanner from "../images/infinite-banner.svg"

const SlidingBanner = ({ reverse }) => {
  const [scrollY, setScrollY] = useState(0)

  const [width, setWidth] = useState(0)

  useEffect(() => {
    document.addEventListener("scroll", event => {
      handleScroll()
    })
    setWidth(document.documentElement.clientWidth * 20)
    return () => {
      document.removeEventListener("scroll", event => {
        handleScroll()
      })
    }
  }, [])

  function handleScroll() {
    const winScroll =
      document.body.scrollTop || document.documentElement.scrollTop

    const height =
      document.documentElement.scrollHeight -
      document.documentElement.clientHeight

    setScrollY(winScroll * 2)
  }

  const middle = width / 2

  let bannerContent = (
    <div
      className="relative whitespace-nowrap h-20"
      style={{
        backgroundImage: `url(${infiniteBanner})`,
        backgroundPositionY: "center",
        backgroundRepeatY: "no-repeat",
        width: `${width}px`,
        left: reverse ? `-${middle - scrollY}px` : `-${middle + scrollY}px`,
        backgroundSize: `auto 25px`,
      }}
    >
      <span
        role="img"
        aria-label="Graphic Design, Content Creation, Web Design, Hospitality Concept Design, Photography"
      >
        {" "}
      </span>
    </div>
  )

  return (
    <div
      className={
        "bg-createneongreen h-20 text-2xl overflow-hidden border-black border-b-2 " +
        (reverse ? "" : "border-t-2")
      }
    >
      {bannerContent}
    </div>
  )
}

export default SlidingBanner
